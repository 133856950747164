import React from 'react';

import styles from './ListThumbnailFooter.module.scss';
import { useModal } from '@components/Modals/useModal';
import useNavigateWithBase from '@hooks/useNavigateWithBase';
import { ButtonType } from '@ingka/button';
import iconArrowRight from '@ingka/ssr-icon/paths/arrow-right';
import ellipsisIcon from '@ingka/ssr-icon/paths/ellipses-vertical';
import Text from '@skapa/Text';
import { handleKeyboardEvent } from '@utils/keyboardEvents';
import { clsx } from 'clsx';
import useTranslations from 'context/Translations';

import { SourceList } from 'apis/types';

import useFormatDateText from 'hooks/useFormatDateText';

import Button from 'skapa/Button';

type ListThumbnailFooterProps = {
    list: SourceList;
    redirectLink: string;
    standAlone?: boolean;
    smallDevice?: boolean;
};

const ListThumbnailFooter: React.FC<ListThumbnailFooterProps> = ({
    list,
    redirectLink,
    standAlone,
    smallDevice,
}: ListThumbnailFooterProps) => {
    const { showModal } = useModal();
    const { push } = useNavigateWithBase();
    const translate = useTranslations();
    const formatDateText = useFormatDateText();
    const dateText = formatDateText(list.updated);
    const showButton = standAlone || !smallDevice;

    const onRedirect = () => {
        push(`${redirectLink}`, {
            shallow: true,
        });
    };

    const additionalButtonProps =
        (!standAlone && {
            ssrIcon: iconArrowRight,
            iconOnly: true,
            type: 'tertiary' as ButtonType,
        }) ||
        {};

    return (
        <div className={clsx(styles.wrapper, standAlone && styles.standAlone)}>
            <div
                className={styles.listInfo}
                role="button"
                aria-label={`${translate('LISTOVERVIEW_LISTS_THUMBNAIL_ARIA', {
                    listName: list.name,
                })}, ${dateText}`}
                onClick={() => onRedirect()}
                onKeyDown={handleKeyboardEvent(['enter'], () => onRedirect())}
                tabIndex={0}
            >
                <Text headingSize="s" tagName="h4" className={styles.listName}>
                    {list.name}
                </Text>
                <Text bodySize="m" tagName="p">
                    {dateText}
                </Text>
            </div>
            {showButton && (
                <Button
                    type="primary"
                    text={translate(
                        'LISTOVERVIEW_LISTS_THUMBNAIL_ACTIVE_BUTTON'
                    )}
                    small
                    aria-label={`${translate(
                        'LISTOVERVIEW_LISTS_THUMBNAIL_ARIA',
                        {
                            listName: list.name,
                        }
                    )}, ${dateText}`}
                    onClick={() => onRedirect()}
                    {...additionalButtonProps}
                />
            )}
            <Button
                small
                iconOnly
                type="tertiary"
                ssrIcon={ellipsisIcon}
                aria-label={translate('LISTDETAIL_SETTINGS_TITLE2')}
                onClick={() => {
                    showModal('ListMenu', {
                        list,
                        isLandingPage: true,
                    });
                }}
            />
        </div>
    );
};

export default ListThumbnailFooter;
