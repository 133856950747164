import React from 'react';

export type EventKey =
    | 'enter'
    | 'escape'
    | 'space'
    | 'backspace'
    | 'arrowup'
    | 'arrowdown'
    | 'arrowleft'
    | 'arrowright'
    | 'arrowdelete'
    | 'tab';

export const handleKeyboardEvent =
    <T extends Element>(
        eventKeys: EventKey[],
        callback: () => void
    ): React.KeyboardEventHandler<T> =>
    (event: React.KeyboardEvent<T>) => {
        const key = event.key?.toLowerCase();
        const eventKey = toEventKey(key);

        if (eventKeys.includes(eventKey)) {
            callback();
        }
    };

const toEventKey = (key: string): EventKey => {
    if (key === ' ') {
        return 'space';
    } else {
        return key as EventKey;
    }
};
